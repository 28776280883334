.spincontainer {
  position: relative;
}

.invalidBackground{
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 999;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px
}
.invalidBackgroundsmall {
  font-weight: 300;
  font-size: 1.3rem;
  margin: 0;
}

.invalidBackgroundbold {
 font-weight: 800;
 font-size: 1.7rem;
}

.wheelContainer {
  width: 100vw;
  height: auto;
  max-width: 400px;
  margin: 0 auto;
  padding: 12rem 0 12rem 0;
  /* padding: 25vh 0 25vh 0; */
  position: relative;
  display: flex;
  justify-content: center;
}

.displayMessageBottle {
  position: absolute;
  width: 100%;
  top: 36.6%;
  opacity: 1;
  z-index: -6;
}

.zoomout {
  animation: fade-out-keyframes 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}
.moveup {
  animation: move-up 0.5s cubic-bezier(.21,-0.07,.51,-0.21) forwards;
}

.tandc p {
  text-align: center;
  max-width: 580px;
  /* margin: 40px auto 0; */
  font-size: 14px;
  position: relative;
  /* bottom: -50px; */
}

.tandcwheel {
  text-align: center;
  max-width: 580px;
  margin: 0px auto 0;
  font-size: 14px;
  position: relative;
  bottom: -50px;
}

.mealDealArrow {
  position: absolute;
  margin: 0 auto;
  top: 0;
  margin-top: 5px;
  max-width: 250px;
}

.mealDealArrownl {
  position: absolute;
  margin: 0 auto;
  top: 0;
  margin-top: 5px;
  max-width: 327px!important;
  transition: filter 0.1s ease-out;
}

.addMarginTop {
  margin-top: 8px;
}

.addCustomMargin {
  margin-top: 11px;
}

.zIndexUp {
  z-index: 5;
}

.spinButton {
  position: absolute;
  text-align: center;
  top: 45%;
  max-width: 150px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.buttonStyle {
  width: 100%;
  opacity: 1;
  max-width: 210px;
  cursor: pointer;
  transform-origin: center;
  filter: drop-shadow(0px 3px 2px #333333) brightness(1.1);
  transition: filter 0.1s ease-out
}

.buttonStyle:hover {
  filter: drop-shadow(0px 3px 2px #333333) brightness(1.2);
}

.buttonStyle:active {
  filter: drop-shadow(0px 3px 2px #333333) brightness(0.9);
  margin-top: 2px;
  transform: scale(0.96);
}

.prizetowin {
  text-align: center;
  position: absolute;
  bottom: -28px;
  width: 90%;
  z-index: 2;
}
.prizetowinfr {
  text-align: center;
  position: absolute;
  bottom: -80px;
  max-width: 220px;
  width: 90%;
  z-index: 2;
}
.displayMessage {
  position: absolute;
  width: 100%;
  top: -50px;
  z-index: -6;
}

.placeinfront {
  z-index: 1!important;
}

.debugOption {
  margin: 5px;
  background: #f4f4f4;
  border: 1px solid #b9b9b9;
  min-width: 192px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.debugOption input {
  font-size: 0.8rem;
  width: 100%;
}

.debugOption button {
  font-size: 0.8rem;
  width: 100%;
}

.displayMessageEmail{
  text-align: center;
  padding: 12px 20px;
  border-radius: 20px;
  border: 4px solid #006638;
  background-color: white!important;
  width: 100%;
  max-width: 265px;
  font-weight: bold;
  color: #0b8a3d!important;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  bottom: 15px;
  line-height: 1.3rem;
  opacity: 0;
}

.displayMessageEmailAnim {
  animation: fade-in-keyframes 0.35s ease-in forwards;
  animation-delay: 2.5s;
}

.hidden {
  display: none;
}

.rejour-button {
  background: #038938;
  color: white;
  font-weight: bold;
  border: 4px solid white;
  border-radius: 18px;
  box-shadow: 0px 3px 1px 2px #004215;
  font-size: 1.1rem;
  position: relative;
  right: -50%;
  top: 2vh;
  max-width: 170px;
  width: 100%;
  height: 60px;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.rejour-anim {
  animation: fade-in-keyframes 0.23s ease-in 2.5s forwards;
}

.wheelPointer {
  position: absolute;
  width: 100%;
  max-width: 65px;
  margin: 0 auto;
  top: 201px;
  left: -5px;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.wheelPointernl {
  position: absolute;
  width: 100%;
  max-width: 88px;
  margin:0 auto;
  top: 211px;
  left: -5px;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.wheelPointerfr {
  position: absolute;
  width: 100%;
  max-width: 53px;
  margin: 0 auto;
  top: 33%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
}

@keyframes move-up {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-110%);
  }
}
@keyframes fade-out-keyframes {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
    bottom: 35px;
  }
  to {
    opacity: 1;
    bottom: 15px;
  }
}

@media only screen and (max-width: 376px) {
  .mealDealArrow {
    max-width: 250px;
  }
  .wheelContainer {
    padding-top: 11rem;
  }
  .wheelPointer {
    top: 176px;
    max-width: 65px;
  }
  .wheelPointernl {
    top: 172px;
    max-width: 65px;
  }
}
@media only screen and (max-width: 321px) {
  .mealDealArrow {
    max-width: 260px;
  }
  .wheelContainer {
    padding-top: 11rem;
  }
  .wheelPointer {
    top: 171px;
    max-width: 50px;
  }
  .wheelPointernl {
    top: 171px;
    max-width: 50px;
  }
}